<div class="row">
  <span class="titre col-12"><i class="fas fa-link" style="margin-right: 10px;"></i>LIENS</span>
  <a class="rd col-12" href="http://flshm.univh2c.ma/data-ng-x2021/emploi/" target="_blank">EMPLOI DU TEMPS</a>
  <a class="rd3 col-12" href="http://attestation.flshm.ma/" target="_blank">E-DOCUMENT</a>
  <a class="rd col-12" href="https://e-learning.univh2c.ma/" target="_blank">E-LEARNING</a>
  <a class="rd2 col-12" href="https://ent.univh2c.ma/" target="_blank">ENT</a>
  <!--<a class="rd5 col-12" href="https://sicareme.com/" target="_blank">ASSURANCE</a>-->
  <a class="rd col-12" href="https://e-bourse-maroc.onousc.ma/" target="_blank">BOURSES</a>
  <a class="rd3 col-12" href="http://flshm.univh2c.ma/convocation/" target="_blank">CONVOCATION</a>
  <a class="rd4 col-12" href="https://recherche-scientifique-flshm.ma/" target="_blank">REVUE</a>

</div>
