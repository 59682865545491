import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CpComponent } from './adm/cp/cp.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './site/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './adm/login/login.component';
import { PageErrComponent } from './adm/page-err/page-err.component';
import { CpSlideComponent } from './adm/cp-slide/cp-slide.component';
import { CpMenuComponent } from './adm/cp-menu/cp-menu.component';
import { CpPublicationComponent } from './adm/cp-publication/cp-publication.component';
import { PublicationComponent } from './site/publication/publication.component';
import { MenuSiteComponent } from './site/menu-site/menu-site.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons'
import { FooterComponent } from './site/footer/footer.component';
import { PagePublicationsComponent } from './site/page-publications/page-publications.component';
import { PageSlidesComponent } from './site/page-slides/page-slides.component';
import { BoutonsComponent } from './site/boutons/boutons.component';
import { LicenceComponent } from './site/licence/licence.component';
import { MasterComponent } from './site/master/master.component';
import { DoctoratComponent } from './site/doctorat/doctorat.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { CpPaginationComponent } from './adm/cp-pagination/cp-pagination.component';
import { LiensComponent } from './site/liens/liens.component';
import { CpStatComponent } from './adm/cp-stat/cp-stat.component';
import { StatisticsComponent } from './site/statistics/statistics.component';
import { CpUserComponent } from './adm/cp-user/cp-user.component';
import { VideoComponent } from './site/video/video.component';
import { CpVideoComponent } from './adm/cp-video/cp-video.component';
import { NotificationComponent } from './adm/notification/notification.component';



@NgModule({
  declarations: [
    AppComponent,
    CpComponent,
    HomeComponent,
    LoginComponent,
    PageErrComponent,
    CpSlideComponent,
    CpMenuComponent,
    CpPublicationComponent,
    PublicationComponent,
    MenuSiteComponent,
    FooterComponent,
    PagePublicationsComponent,
    PageSlidesComponent,
    BoutonsComponent,
    LicenceComponent,
    MasterComponent,
    DoctoratComponent,
    CpPaginationComponent,
    LiensComponent,
    CpStatComponent,
    StatisticsComponent,
    CpUserComponent,
    VideoComponent,
    CpVideoComponent,
    NotificationComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    EditorModule,
    HttpClientModule,
    NgxPaginationModule,
    ShareButtonsModule.withConfig({
      debug:true,
    }),
    ShareIconsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    NgxSpinnerModule,
    NgxScrollTopModule,


  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
