import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CpPaginationComponent } from './adm/cp-pagination/cp-pagination.component';
import { CpPublicationComponent } from './adm/cp-publication/cp-publication.component';
import { CpSlideComponent } from './adm/cp-slide/cp-slide.component';
import { CpStatComponent } from './adm/cp-stat/cp-stat.component';
import { CpUserComponent } from './adm/cp-user/cp-user.component';
import { CpVideoComponent } from './adm/cp-video/cp-video.component';
import { CpComponent } from './adm/cp/cp.component';
import { LoginComponent } from './adm/login/login.component';
import { NotificationComponent } from './adm/notification/notification.component';
import { PageErrComponent } from './adm/page-err/page-err.component';
import { CpGuard } from './cp.guard';
import { DoctoratComponent } from './site/doctorat/doctorat.component';
import { HomeComponent } from './site/home/home.component';
import { LicenceComponent } from './site/licence/licence.component';
import { MasterComponent } from './site/master/master.component';
import { PagePublicationsComponent } from './site/page-publications/page-publications.component';

const routes: Routes = [
  {path:'cp',component:CpComponent,canActivate:[CpGuard]},
  {path:'cpslide',component:CpSlideComponent,canActivate:[CpGuard]},
  {path:'cpublication',component:CpPublicationComponent,canActivate:[CpGuard]},
  {path:'cpagination',component:CpPaginationComponent,canActivate:[CpGuard]},
  {path:'cpstat',component:CpStatComponent,canActivate:[CpGuard]},
  {path:'cpuser',component:CpUserComponent,canActivate:[CpGuard]},
  {path:'cpvideo',component:CpVideoComponent,canActivate:[CpGuard]},
  {path:'notif',component:NotificationComponent,canActivate:[CpGuard]},
  {path:'home',component:HomeComponent},
  {path:'licence',component:LicenceComponent},
  {path:'master',component:MasterComponent},
  {path:'doctorat',component:DoctoratComponent},
  {path:'publications',component:PagePublicationsComponent},
  {path:'mhslf-ng-x21',component:LoginComponent},
  {path:'pageErr',component:PageErrComponent},
  {path:'',redirectTo:'/home',pathMatch:'full'},
  {path:'**',redirectTo:'/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],


exports: [RouterModule]
})
export class AppRoutingModule { }
